(function($){
    $.fn.extend({
        beforeAfterVertical: function() {

            $(this).each(function() {

                var baContainer = $(this);
                var percentage = 50;
                var afterImage = $('img:last', baContainer);
                afterImageH = afterImage.width();
                afterImageW = afterImage.height();

                $(baContainer).width(afterImageH);

                $('img:first', baContainer).wrap('<div class="ba-slider-before-image"/>').height(afterImageW);
                $('.ba-slider-before-image', baContainer).css({height: percNum(percentage)})
                $('img', baContainer).addClass('ba-slider-image');

                html = '<div class="ba-control"><div class="ba-control-area"><div class="ba-control-bar"><span class="ba-left-arrow" /><span class="ba-control-handle" /><span class="ba-right-arrow" /></div></div></div>';

                baContainer.append(html);
 
                var slider  = baContainer.find('.ba-control');
                var baBar = slider.find('.ba-control-area .ba-control-handle');
                var baHoverEl = baContainer.find('*');
                var startOffset, holderOffset, sliderheight, handleheight;

                baBar.css({top: percNum(percentage)});

                // EVENTS

                // Resize
                $(window).on('resize', resizeHandler);

                // Drag
                baBar.on('mousedown', function(e) {
                    e.preventDefault(); 
                    holderOffset = slider.offset().top;
                    startOffset = baBar.offset().top - holderOffset;
                    sliderheight = slider.height();
                    
                    $(document).on('mousemove', moveHandler).on('mouseup', stopHandler);
                });

                // Hover
                baContainer.on('mouseover', function(e) {
                    $('.ba-left-arrow, .ba-right-arrow, .ba-control-handle', baContainer).filter(':not(:animated)').fadeIn();
                });
                baContainer.on('mouseleave', function(e) {
                    $('.ba-left-arrow, .ba-right-arrow, .ba-control-handle', baContainer).fadeOut();
                });

                // Click
                baContainer.on('click', function(e) {
                    e.preventDefault(); 
                    var clickX = e.pageY - $(this).offset().top;
                    var posP = (clickX / slider.height())*100;

                    posY = Math.round(Math.min(Math.max(0, posP), 100));

                    baBar.stop().animate({
                        top: percNum(posY)
                    });

                    $('.ba-slider-before-image', baContainer).stop().animate({
                        height: percNum(posY)
                    });           
                });

                baContainer.on('touchmove', function(e) {
                    e.preventDefault(); 

                    var touchX = e.originalEvent.targetTouches[0].pageY;
                    var clickX = touchX - slider.offset().top;
                    var posP = (clickX / slider.height())*100;

                    posY = Math.round(Math.min(Math.max(0, posP), 100));

                    baBar.css({
                        top: percNum(posY)
                    });

                    $('.ba-slider-before-image', baContainer).height(percNum(posY));
                });

                // FUNCTIONS
                function moveHandler(e) {
                    var posY = e.pageY - holderOffset;
                    var posP = (posY / sliderheight)*100;

                    posY = Math.floor(Math.min(Math.max(0, posP), 100));
                    
                    baBar.css({
                        top: percNum(posY)
                    });

                    $('.ba-slider-before-image', baContainer).height(percNum(posY));
                }
                function stopHandler() {
                    $(document).off('mousemove', moveHandler).off('mouseup', stopHandler);
                }
                function resizeHandler(){
                    afterImageH = afterImage.width();
                    afterImageW = afterImage.height();
                    $('.ba-slider', baContainer).width(afterImageH);
                    $('.ba-control', baContainer).height(afterImageH);
                    $('.ba-slider-before-image img', baContainer).height(afterImageW);
                    baContainer.width(afterImageH);
                }
                function percNum(num) {
                    return num + '%';
                }

                // Clean up of overflow issues
                resizeHandler();

            });
        }
    });
})(jQuery);