(function($, window, document) {
    //"use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $header = $('.headerWrap'),
            $goBottom = $('#go_bottom'),
            $goBottom2 = $('#go_bottom_second'),
            $hamburger = $('.hamburger'),
            $nav = $('.main_header'),
            $goTop = $('.go_top'),
            $dropdownFooterBtn = $('.dropdown a'),
            $dropdownFooterList = $('.dropdown ul'),
            $infoBox = $('.info_box'),
            $contactBtn = $('.contactBtn'),
            $contactWrap = $('.contact_wrapper'),
            $contactClose = $('.close_btn'),
            $activeClass = 'is--active',
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

        //OFI
        objectFitImages();

        //Header scroll animation
        $doc.scroll(function() {
            if ($doc.scrollTop() >= $header.outerHeight()) {
              $header.addClass('is--sticky');
            } else {
              $header.removeClass('is--sticky');
            }
        });

        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('#header').outerHeight();

        $(window).scroll(function(){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 150);

        function hasScrolled() {
            var st = $(this).scrollTop();
            if (Math.abs(lastScrollTop - st) <= delta) {
                return;
            }

            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('.main_header').addClass('header-up');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('.main_header' ).removeClass('header-up');
                }
            }
            
            lastScrollTop = st;
        }

        //Close Contact Popup
        $contactBtn.on('click' , function(e){
            $contactWrap.toggleClass($activeClass);
        });

        //Close Contact Popup
        $contactClose.on('click' , function(e){
            $contactWrap.toggleClass($activeClass);
        });
        
        //Close popup with ESC
        $(document).keyup(function(e) {
            if (e.which == 27 && $contactWrap.hasClass('is--active')) {
                $contactWrap.toggleClass($activeClass);
            }
        });

        //Open Menu
        $hamburger.on('click', function(e){
            $nav.toggleClass("is--active");
            $body.toggleClass('menu--active');
        });

        
        //Go bottom for a section
        $goBottom.on('click', function(e){
            var $sectionHeight = $goBottom.parent().outerHeight();
            jQuery("html, body").animate({ 
                    scrollTop: ($sectionHeight) 
            }, 1000);
        });

        //Go bottom for a section 
        $goBottom2.on('click', function(e){
            var $sectionHeight = $goBottom2.parent().outerHeight();
            jQuery("html, body").animate({ 
                    scrollTop: ($sectionHeight - 60) 
            }, 1000);
        });

        //Toggle Info Box in iPad
        $infoBox.on('click', 'span' , function(e){
            $(this).parent().toggleClass($activeClass);
        });

        //Go TOP btn
        $goTop.on('click', function(e){
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });
        //Toggle List of Language
        $dropdownFooterBtn.on('click', function(e){
            $(this).parent().find('ul').toggle('fast');
        });
        //Blog Slider
        $('#blog_slider').flexslider({
            animation: "slide",
            controlNav: false,
            loop: true,
            controlNav: "dots",
            animationLoop: true,
            drag: true
        });

        //Case Studies Slider
        $('#case_studies_slider').owlCarousel({
            items: 6,
            loop: true,
            animation: "slide",
            nav: false,
            responsiveClass:true,
            responsive:{ 
                0:{
                    items:1 
                },
                768:{
                    items:2
                },
                1024:{
                    items:3
                },
                1200:{
                    items:4
                },
                1500:{
                    items:5
                },
                1700:{
                    items:6
                }
            }
        });
        
        //Our Partner Home Slider
        $('#our_clients_slider').flexslider({
            animation: "slide",
            controlNav: false,
            loop: true,
            animationLoop: true,
            controlNav: "thumbnails",
            drag: true,
            slideshow: false,
            sync: "#our_clients_thumb",
            start: function(){
                $('.flex-control-nav .flex-active').parent('li').addClass('flex-active').siblings().removeClass('flex‌​-active');
            },
            after: function(){
                $('.flex-control-nav li').removeClass('flex-active');
                $('.flex-control-nav .flex-active').parent('li').addClass('flex-active').siblings().removeClass('flex‌​-active');
            }
        });
        $('.vertical-slider').beforeAfterVertical();

        $('.horisontal-slider').beforeAfter();


        AOS.init();
        if ($('.row').hasClass('inf-scroll')) {
            $('.inf-scroll').initInfScroll();
        }

    });
}(window.jQuery, window, document));
